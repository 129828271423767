//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class CategoriaServices {
   
    indicadoresVentaGeneralConsultar(
        datos   
    ) {
        return http.get("ventas/indicadores/VentaGeneral", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
    indicadoresArqueoDeCajasConsultar(
        datos   
    ) {
        return http.get("ventas/indicadores/ArqueoDeCajas", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
    indicadoresProductosVendidos(
        datos   
    ) {
        return http.get("ventas/indicadores/ProductosVendidos", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
    
    indicadoresArqueoDeCajasGeneralConsultar(
        datos   
    ) {
        return http.get("ventas/indicadores/ArqueoDeCajasGeneral", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }
  
}


export default new CategoriaServices();