<template>
  <dvi>
    <v-row>
      <v-col cols="12">
        <DatePicker :dense="true" :label="'Fecha'" :outlined="true" v-model="FechaGeneral" />
      </v-col>
      <v-col cols="12">
        <IndicadoresGenerales ref="IndicadoresGeneralesRef" :Fecha="FechaGeneral" />
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col cols="12">
            <CardTasa />
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6">
                <v-hover v-slot="{ hover }">
                  <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="AbrirDialog()">
                    <v-card-text>
                      <v-row>
                        <v-col cols="3" style="display: flex; align-items: center">
                          <v-icon color="info">{{ icons.mdiAccountConvert }} </v-icon>
                        </v-col>
                        <v-col cols="9">
                          <h4>Asignar</h4>
                          <span>Cajero</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" sm="6">
                <v-hover v-slot="{ hover }">
                  <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="ExportarPDF()">
                    <v-card-text>
                      <v-row>
                        <v-col cols="3" style="display: flex; align-items: center">
                          <v-icon color="primary">{{ icons.mdiFilePdfBox }} </v-icon>
                        </v-col>
                        <v-col cols="9">
                          <h4>Exportar</h4>
                          <span>PDF</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" sm="6">
                <v-hover v-slot="{ hover }">
                  <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="exportExcel()">
                    <v-card-text>
                      <v-row>
                        <v-col cols="3" style="display: flex; align-items: center">
                          <v-icon color="success">{{ icons.mdiFileExcelBox }} </v-icon>
                        </v-col>
                        <v-col cols="9">
                          <h4>Exportar</h4>
                          <span>Excel</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12" sm="6">
                <v-hover v-slot="{ hover }">
                  <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="exportDatos()">
                    <v-card-text>
                      <v-row>
                        <v-col cols="3" style="display: flex; align-items: center">
                          <v-icon color="success">{{ icons.mdiDatabaseClock }} </v-icon>
                        </v-col>
                        <v-col cols="9">
                          <h4>Exportar</h4>
                          <span>Datos del dia</span>
                          <span v-if="exportandoDatos">
                            <br />
                            <span>Cargando</span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="12">
                <v-hover v-slot="{ hover }">
                  <v-card :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }" @click="exportTodosDatos()">
                    <v-card-text>
                      <v-row>
                        <v-col cols="3" style="display: flex; align-items: center">
                          <v-icon color="success">{{ icons.mdiDatabaseExport }} </v-icon>
                        </v-col>
                        <v-col cols="9">
                          <h4>Exportar</h4>
                          <span>Exportar toda la data</span>
                          <span v-if="exportandoDatos">
                            <br />
                            <span>Cargando</span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col sm="12" md="12" lg="8">
        <v-card>
          <div>
            <v-card-title> Arqueo de cajas </v-card-title>
            <div style="height: 405px; overflow-y: auto">
              <TablaCaja ref="TablaCajaRef" :Fecha="FechaGeneral" />
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <div>
            <v-card-title> Arqueo General </v-card-title>
            <div style="max-height: 300px; overflow-y: auto">
              <TablaArqueoGeneral ref="TablaArqueoGeneralRef" :Fecha="FechaGeneral" />
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <div>
            <v-card-title> Productos Vendidos </v-card-title>
            <div style="max-height: 300px; overflow-y: auto">
              <TablaProductosVendidos ref="TablaProductosVendidosRef" :Fecha="FechaGeneral" />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <ModalListaCajas ref="ModalListaCajasRef"></ModalListaCajas>
    <Pdf ref="pdfRef"></Pdf>
  </dvi>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

// demos
import DashboardCongratulationJohn from '@/views/dashboard/DashboardCongratulationJohn.vue'
import DashboardStatisticsCard from '@/views/dashboard/DashboardStatisticsCard.vue'
import DashboardCardTotalEarning from '@/views/dashboard/DashboardCardTotalEarning.vue'
import DashboardCardDepositAndWithdraw from '@/views/dashboard/DashboardCardDepositAndWithdraw.vue'
import DashboardCardSalesByCountries from '@/views/dashboard/DashboardCardSalesByCountries.vue'
import DashboardWeeklyOverview from '@/views/dashboard/DashboardWeeklyOverview.vue'
import DashboardDatatable from '@/views/dashboard/DashboardDatatable.vue'
import DialogPrueba from '@/views/dashboard/Dialog.vue'
import DataTableExample from '@/views/dashboard/DataTableExample.vue'
import CardTasa from './componentes/CardTasa.vue'
import DatePicker from '@/components/DatePicker.vue'
import { ref } from '@vue/composition-api'
import IndicadoresGenerales from './componentes/IndicadoresGenerales.vue'
import TablaCaja from './componentes/TablaCaja.vue'
import TablaArqueoGeneral from './componentes/TablaArqueoGeneral.vue'
import ModalListaCajas from './componentes/ModalListaCajas.vue'
import { mdiAccountConvert, mdiFilePdfBox, mdiFileExcelBox, mdiDatabaseExport, mdiDatabaseClock } from '@mdi/js'
import moment from 'moment'
import TablaProductosVendidos from './componentes/TablaProductosVendidos.vue'
import Pdf from '@/components/Pdf.vue'
import funciones from '@/funciones/funciones'
import store from '@/store'
import { acciones } from '@/modulos'
import ExportImportServices from '@/api/servicios/ExportImportServices'
export default {
  components: {
    IndicadoresGenerales,
    CardTasa,
    StatisticsCardVertical,
    DashboardCongratulationJohn,
    DashboardStatisticsCard,
    DashboardCardTotalEarning,
    DashboardCardDepositAndWithdraw,
    DashboardCardSalesByCountries,
    DashboardWeeklyOverview,
    DashboardDatatable,
    DialogPrueba,
    DataTableExample,
    DatePicker,
    TablaCaja,
    TablaArqueoGeneral,
    TablaProductosVendidos,
    ModalListaCajas,
    Pdf,
  },
  setup() {
    const FechaGeneral = ref(moment().format('YYYY-MM-DD'))
    const ModalListaCajasRef = ref(null)
    const IndicadoresGeneralesRef = ref(null)
    const TablaCajaRef = ref(null)
    const TablaArqueoGeneralRef = ref(null)
    const TablaProductosVendidosRef = ref(null)
    const pdfRef = ref(null)
    const exportandoDatos = ref(false)
    function uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
      )
    }

    const exportTodosDatos = async () => {
      let Hash = 0
      if (!funciones.validarAcciones(acciones.EXPORTAR_TODA_LA_DATA, store.state.sucursalSelect.id)) {
        Hash = await funciones.HashsConsultar()
      } else {
        Hash = 3
      }

      if (Hash == 3) {
      exportandoDatos.value = true
      ExportImportServices.ExportarDatosTodos()

        .then(response => {
          console.log(response.data)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'ExDaTodo-' + store.state.sucursalSelect.nombre.toString() + '-' + uuidv4() + '.data',
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          exportandoDatos.value = false
        })
      } else {
        Vue.swal({
          icon: 'error',
          text: `No tienes permiso para realizar esta accion`,
        })
      }
    }

    const exportDatos = async () => {
    
       
        exportandoDatos.value = true
        ExportImportServices.ExportarDatos({
          idSucursal: store.state.sucursalSelect.id,
          fechaDesde: FechaGeneral.value,
          fechaHasta: FechaGeneral.value,
        })

          .then(response => {
            console.log(response.data)
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              'ExDaDi' +
                store.state.sucursalSelect.nombre.toString() +
                '-' +
                FechaGeneral.value +
                FechaGeneral.value +
                uuidv4() +
                '.data',
            )
            document.body.appendChild(link)
            link.click()
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            exportandoDatos.value = false
          })
      
    }
    const exportExcel = () => {
      const datos = {
        IndicadoresGenerales: IndicadoresGeneralesRef.value.datos,
        TablaCaja: TablaCajaRef.value.datos,
        TablaArqueoGeneral: TablaArqueoGeneralRef.value.datos,
        TablaProductosVendidos: TablaProductosVendidosRef.value.datos,
      }

      let datoEx = {
        nombreDocumento: `Venta General: ${funciones.formatoFecha(FechaGeneral.value, 1)}`,
        hojas: [],
      }

      let IndicadoresGeneralesRow = [
        {
          v: funciones.formatoFecha(FechaGeneral.value, 1),
          t: 's',
        },
        {
          v: datos.IndicadoresGenerales.montoSubTotal,
          t: 'n',
          s: {
            numFmt: '0.00',
          },
        },
        {
          v: datos.IndicadoresGenerales.montoIva,
          t: 'n',
          s: {
            numFmt: '0.00',
          },
        },
        {
          v: datos.IndicadoresGenerales.montoTotal,
          t: 'n',
          s: {
            numFmt: '0.00',
          },
        },
      ]
      let IndicadoresGenerales = {
        nombreHoja: 'Venta General',
        cabecera: ['Fecha', 'Ventas', 'Iva', 'Total'],
        rows: [IndicadoresGeneralesRow],
        anchoColumanas: [{ width: 15 }, { width: 15 }, { width: 15 }],
      }

      let TablaCajaDatos = []
      datos.TablaCaja.forEach(caja => {
        caja.monedas.forEach(monedas => {
          monedas.formasPago.forEach(forma => {
            TablaCajaDatos.push([
              {
                v: caja.caja.descripcion,
                t: 's',
              },
              {
                v: monedas.moneda.nombre,
                t: 's',
              },
              {
                v: forma.formaPago.descripcion,
                t: 's',
              },
              {
                v: `${forma.montoPagoMoneda}`,
                t: 'n',
                s: {
                  numFmt: '0.00',
                },
              },
              {
                v: `${forma.montoPagoReal}`,
                t: 'n',
                s: {
                  numFmt: '0.00',
                },
              },
            ])
          })
        })
      })
      let TablaCaja = {
        nombreHoja: 'Arqueo por caja',
        cabecera: ['Caja', 'Moneda', 'FormaPago', 'Monto Moneda', 'Monto ' + funciones.monedaPrincipal().simbolo],
        rows: TablaCajaDatos,
        anchoColumanas: [{ width: 15 }, { width: 30 }, { width: 20 }, { width: 16 }, { width: 16 }],
      }

      let TablaArqueoGeneralDatos = []
      datos.TablaArqueoGeneral.forEach(caja => {
        caja.monedas.forEach(monedas => {
          monedas.formasPago.forEach(forma => {
            TablaArqueoGeneralDatos.push([
              {
                v: monedas.moneda.nombre,
                t: 's',
              },
              {
                v: forma.formaPago.descripcion,
                t: 's',
              },
              {
                v: `${forma.montoPagoMoneda}`,
                t: 'n',
                s: {
                  numFmt: '0.00',
                },
              },
              {
                v: `${forma.montoPagoReal}`,
                t: 'n',
                s: {
                  numFmt: '0.00',
                },
              },
            ])
          })
        })
      })
      let TablaArqueoGeneral = {
        nombreHoja: 'Arqueo por Genreal',
        cabecera: ['Moneda', 'FormaPago', 'Monto Moneda', 'Monto ' + funciones.monedaPrincipal().simbolo],
        rows: TablaArqueoGeneralDatos,
        anchoColumanas: [{ width: 30 }, { width: 20 }, { width: 16 }, { width: 16 }],
      }

      const TablaProductosVendidosDatos = []

      datos.TablaProductosVendidos.forEach(producto => {
        TablaProductosVendidosDatos.push([
          { v: producto.producto.id, t: 's' },
          { v: producto.producto.barra, t: 's' },
          { v: producto.producto.referencia, t: 's' },
          { v: producto.producto.nombre, t: 's' },
          {
            v: producto.numeroUnidades,
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
          {
            v: `${producto.iva}`,
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
          {
            v: `${producto.pvp}`,
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
          {
            v: `${producto.pvp + producto.iva}`,
            t: 'n',
            s: {
              numFmt: '0.00',
            },
          },
        ])
      })

      let TablaProductosVendidos = {
        nombreHoja: 'Prductos Vendidos',
        cabecera: ['Id', 'Barra', 'Ref', 'Producto', 'Unidades', 'Iva ', 'Base ', 'Total '],
        rows: TablaProductosVendidosDatos,
        anchoColumanas: [
          { width: 5 },
          { width: 20 },
          { width: 10 },
          { width: 30 },
          { width: 15 },
          { width: 15 },
          { width: 15 },
        ],
      }

      datoEx.hojas.push(IndicadoresGenerales)
      datoEx.hojas.push(TablaArqueoGeneral)
      datoEx.hojas.push(TablaCaja)
      datoEx.hojas.push(TablaProductosVendidos)

      funciones.exportarDataExcelVariasHojas(datoEx)
    }

    const ExportarPDF = () => {
      const datos = {
        IndicadoresGenerales: IndicadoresGeneralesRef.value.datos,
        TablaCaja: TablaCajaRef.value.datos,
        TablaArqueoGeneral: TablaArqueoGeneralRef.value.datos,
        TablaProductosVendidos: TablaProductosVendidosRef.value.datos,
      }
      console.log('ExportarPDF', datos)

      //cargar formas pago
      /*{ content: funciones.formatoNumeric(datos.IndicadoresGenerales.montoSubTotal) , styles: { halign: 'left' } },
                 { content:  funciones.formatoNumeric(datos.IndicadoresGenerales.montoIva), styles: { halign: 'left' } },
                 { content:  funciones.formatoNumeric(datos.IndicadoresGenerales.montoTotal), styles: { halign: 'left' } },*/

      const TablaCajaDatos = []
      datos.TablaCaja.forEach(caja => {
        TablaCajaDatos.push([{ content: caja.caja.descripcion, colSpan: 4, styles: { halign: 'center' } }])
        caja.monedas.forEach(monedas => {
          monedas.formasPago.forEach(forma => {
            TablaCajaDatos.push([
              { content: monedas.moneda.nombre, styles: { halign: 'left' } },
              { content: forma.formaPago.descripcion, styles: { halign: 'left' } },
              {
                content:
                  funciones.monedaPrincipal().id != monedas.moneda.id
                    ? `${monedas.moneda.simbolo} ${forma.montoPagoMoneda}`
                    : '',
                styles: { halign: 'right' },
              },
              { content: `${funciones.monedaPrincipal().simbolo} ${forma.montoPagoReal}`, styles: { halign: 'right' } },
            ])
          })
          TablaCajaDatos.push([
            { content: 'Total ' + monedas.moneda.nombre, styles: { halign: 'left', fillColor: [203, 203, 203] } },

            { content: '', styles: { halign: 'left', fillColor: [203, 203, 203] } },
            {
              content:
                funciones.monedaPrincipal().id != monedas.moneda.id
                  ? `${monedas.moneda.simbolo} ${monedas.montoPagoMoneda}`
                  : '',
              styles: { halign: 'right', fillColor: [203, 203, 203] },
            },
            {
              content: `${funciones.monedaPrincipal().simbolo} ${monedas.montoPagoReal}`,
              styles: { halign: 'right', fillColor: [203, 203, 203] },
            },
          ])
        })

        TablaCajaDatos.push([
          { content: 'Total ' + caja.caja.descripcion, styles: { halign: 'left', fillColor: [203, 203, 203] } },
          { content: '', styles: { halign: 'left', fillColor: [203, 203, 203] } },
          { content: '', styles: { halign: 'left', fillColor: [203, 203, 203] } },
          {
            content: `${funciones.monedaPrincipal().simbolo} ${caja.montoPagoReal}`,
            styles: { halign: 'right', fillColor: [203, 203, 203] },
          },
        ])
      })

      const TablaArqueoGeneralDatos = []
      datos.TablaArqueoGeneral.forEach(caja => {
        caja.monedas.forEach(monedas => {
          monedas.formasPago.forEach(forma => {
            TablaArqueoGeneralDatos.push([
              { content: monedas.moneda.nombre, styles: { halign: 'left' } },
              { content: forma.formaPago.descripcion, styles: { halign: 'left' } },
              {
                content:
                  funciones.monedaPrincipal().id != monedas.moneda.id
                    ? `${monedas.moneda.simbolo} ${forma.montoPagoMoneda}`
                    : '',
                styles: { halign: 'right' },
              },
              { content: `${funciones.monedaPrincipal().simbolo} ${forma.montoPagoReal}`, styles: { halign: 'right' } },
            ])
          })

          TablaArqueoGeneralDatos.push([
            { content: 'Total ' + monedas.moneda.nombre, styles: { halign: 'left', fillColor: [203, 203, 203] } },

            { content: '', styles: { halign: 'left', fillColor: [203, 203, 203] } },
            {
              content:
                funciones.monedaPrincipal().id != monedas.moneda.id
                  ? `${monedas.moneda.simbolo} ${monedas.montoPagoMoneda}`
                  : '',
              styles: { halign: 'right', fillColor: [203, 203, 203] },
            },
            {
              content: `${funciones.monedaPrincipal().simbolo} ${monedas.montoPagoReal}`,
              styles: { halign: 'right', fillColor: [203, 203, 203] },
            },
          ])
        })
        TablaArqueoGeneralDatos.push([
          { content: 'Total ', styles: { halign: 'left', fillColor: [203, 203, 203] } },
          { content: '', styles: { halign: 'left', fillColor: [203, 203, 203] } },
          { content: '', styles: { halign: 'left', fillColor: [203, 203, 203] } },
          {
            content: `${funciones.monedaPrincipal().simbolo} ${caja.montoPagoReal}`,
            styles: { halign: 'right', fillColor: [203, 203, 203] },
          },
        ])
      })

      const TablaProductosVendidosDatos = []

      datos.TablaProductosVendidos.forEach(producto => {
        TablaProductosVendidosDatos.push([
          { content: producto.producto.id, styles: { halign: 'left' } },
          { content: producto.producto.nombre, styles: { halign: 'left' } },
          { content: producto.numeroUnidades, styles: { halign: 'right' } },
          {
            content: `${funciones.monedaPrincipal().simbolo}.${funciones.formatoNumeric(producto.iva)}`,
            styles: { halign: 'right' },
          },
          {
            content: `${funciones.monedaPrincipal().simbolo}.${funciones.formatoNumeric(producto.pvp)}`,
            styles: { halign: 'right' },
          },
          {
            content: `${funciones.monedaPrincipal().simbolo}.${funciones.formatoNumeric(producto.pvp + producto.iva)}`,
            styles: { halign: 'right' },
          },
        ])
      })

      const datosInit = {
        nombreDocumento: `Venta General: ${funciones.formatoFecha(FechaGeneral.value, 1)}`,

        cabecera1: {
          visible: true,
        },
        titulo: {
          visible: true,
          posicion: 'center',
          texto: 'Ventas General ' + store.state.sucursalSelect.nombre,
          TamanoLetras: 12,
          color: '#7B7B7B',
        },
        cabecera2: {
          visible: true,
          datos: [
            { texto: '' + moment(FechaGeneral.value).format('DD-MM-YYYY'), TamanoLetras: 14, color: '#FF0000' },
            { texto: 'Fecha Exportacion: ' + funciones.formatoFecha(moment(), 1) },
            { texto: 'Hora Exportacion: ' + funciones.formatoFecha(moment(), 2) },
            { texto: 'Empleado: ' + `${store.state.user.nombre} ${store.state.user.apellido}` },
          ],
        },
        sub1: {
          visible: false,
          datos: [
            /*
            { texto: 'Area Origen: ' + datoInit.areaOrigen.nombre },
            { texto: 'Area Destino: ' + datoInit.areaDestino.nombre },
            { texto: 'Estatus:' + datoInit.estatus.descripcion },*/
          ],
        },
        sub2: {
          visible: false,
          datos: [
            /*{ texto: 'Fecha Translado: ' + funciones.formatoFecha(datoInit.fechaTranslado, 1) }*/
          ],
        },
        cuerpo1: {
          visible: true,
          datos: [
            {
              titulo: { texto: 'Venta General', TamanoLetras: 12 },
              columnStyles: {
                3: {
                  halign: 'right',
                },
              },
              tablas: {},
              head: [
                [
                  { content: 'Venta', styles: { halign: 'left' } },
                  { content: 'Iva', styles: { halign: 'center' } },
                  { content: 'Total', styles: { halign: 'right' } },
                ],
              ],
              body: [
                [
                  {
                    content:
                      funciones.monedaPrincipal().simbolo +
                      ' ' +
                      funciones.formatoNumeric(datos.IndicadoresGenerales.montoSubTotal),
                    styles: { halign: 'left' },
                  },
                  {
                    content:
                      funciones.monedaPrincipal().simbolo +
                      ' ' +
                      funciones.formatoNumeric(datos.IndicadoresGenerales.montoIva),
                    styles: { halign: 'center' },
                  },
                  {
                    content:
                      funciones.monedaPrincipal().simbolo +
                      ' ' +
                      funciones.formatoNumeric(datos.IndicadoresGenerales.montoTotal),
                    styles: { halign: 'right' },
                  },
                ],
              ],
            },
            {
              titulo: { texto: 'Arqueo por caja', TamanoLetras: 12 },
              columnStyles: {
                3: {
                  halign: 'right',
                },
              },
              tablas: {},
              head: [
                [
                  { content: 'Moneda', styles: { halign: 'left' } },
                  { content: 'Forma Pago', styles: { halign: 'left' } },
                  { content: 'Monto', styles: { halign: 'right' } },
                  { content: 'Monto ' + funciones.monedaPrincipal().simbolo, styles: { halign: 'right' } },
                ],
              ],
              body: TablaCajaDatos,
            },
            {
              titulo: { texto: 'Arqueo General', TamanoLetras: 12 },
              columnStyles: {
                3: {
                  halign: 'right',
                },
              },
              tablas: {},
              head: [
                [
                  { content: 'Moneda', styles: { halign: 'left' } },
                  { content: 'Forma Pago', styles: { halign: 'left' } },
                  { content: 'Monto', styles: { halign: 'right' } },
                  { content: 'Monto ' + funciones.monedaPrincipal().simbolo, styles: { halign: 'right' } },
                ],
              ],
              body: TablaArqueoGeneralDatos,
            },
            {
              titulo: { texto: 'Productos', TamanoLetras: 12 },
              styles: { fontSize: 7 },
              columnStyles: {
                3: {
                  halign: 'right',
                },
              },
              tablas: {},
              head: [
                [
                  { content: 'Id', styles: { halign: 'left' } },
                  { content: 'Producto', styles: { halign: 'left' } },
                  { content: 'Unidades', styles: { halign: 'right' } },
                  { content: 'Iva', styles: { halign: 'right' } },
                  { content: 'Base', styles: { halign: 'right' } },
                  { content: 'Total', styles: { halign: 'right' } },
                ],
              ],
              body: TablaProductosVendidosDatos,
            },
          ],
        },
      }
      pdfRef.value.generarPDF(datosInit)
    }

    const AbrirDialog = () => {
      ModalListaCajasRef.value.abrir()
    }
    return {
      IndicadoresGeneralesRef,
      TablaCajaRef,
      TablaArqueoGeneralRef,
      FechaGeneral,
      TablaProductosVendidosRef,
      ExportarPDF,
      icons: {
        mdiAccountConvert,
        mdiFilePdfBox,
        mdiFileExcelBox,
        mdiDatabaseExport,
        mdiDatabaseClock,
      },
      ModalListaCajasRef,
      AbrirDialog,
      pdfRef,
      exportExcel,
      exportDatos,
      exportandoDatos,
      exportTodosDatos,
    }
  },
}
</script>
