<template>
    <div>

        <v-row justify="center">
            <v-dialog v-model="dialog" scrollable max-width="780px">
                <v-card   >
                    <v-card-title style="color: white;">
                        Asignar Cajeros {{ store.state.sucursalSelect.nombre }}
                    </v-card-title>
                    <v-card-text>
                        <v-row class="match-height">
                            <v-col cols="12" sm="6" md="4" lg="3" v-for="items in Lista" :key="items.id">
                                <v-card  dark :class="items.usuario != null?'primary':''" :loading="cargandoDatos" class="mx-auto my-12" max-width="374" max-height="120" @click="abrirModalEmpleado(items)">
                                    <v-card-title color="primary">{{ items.descripcion }}</v-card-title>
                                    <v-card-subtitle v-if="items.usuario != null">
                                        Cajero <br>{{ items.usuario.empleado.nombre }} {{ items.usuario.empleado.apellido }}
                                    </v-card-subtitle>
                                    <v-card-subtitle v-else>
                                        Cajero <br>SIN ASIGNAR
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>   
        </v-row>
        <ModalBuscarEmpleado ref="BuscarEmpleadoRef" @getIdEmpleado="getId"></ModalBuscarEmpleado>
    </div>
</template>
<script>
import { ref } from '@vue/composition-api';
import store from '@/store';
import ModalBuscarEmpleado from './ModalBuscarEmpleado.vue';
import CajaServices from '../../../../../api/servicios/CajaServices';
import { mdiPencil, mdiPlus } from '@mdi/js';
import Vue from 'vue'
export default {
    components: { 
        ModalBuscarEmpleado
    },
    setup(props, contex) {
        const dialog = ref(false)
        const Lista = ref([])
        const cargandoDatos = ref(false)
        const BuscarEmpleadoRef = ref(null)
        const abrir = () => {
            cargar();
            dialog.value = true;
        }
        const IdCajaSelect  = ref(null)
        const anulando = ref(false)
        const abrirModalEmpleado = (item) => {
            console.log("abrirModalEmpleado",item)
            IdCajaSelect.value = item.id
            if(item.usuario == null){
                BuscarEmpleadoRef.value.abrir(item.usuario)
            }else{
                anulando.value = true
                Vue.swal({
                    icon: "warning",
                    html:
                        '<h3>¿Seguro que desea desvincular a este cajero? </h3>' +
                        `<p>${item.usuario.empleado.nombre} ${item.usuario.empleado.apellido}</p>`,
                    showCancelButton: true,
                    confirmButtonText: 'Si, Seguro',
                    showLoaderOnConfirm: true,
                    cancelButtonText: 'No',
                    preConfirm: () => {
                        ActualizarCajaUsuario({IdCaja: IdCajaSelect.value, idUsuario: -1})
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                }).then(result => {
                    anulando.value = false
                })
            }
        }

        const getId =  item => {
            console.log("getId",   {IdCaja: IdCajaSelect.value, idUsuario: item.usuario.id}) 
            ActualizarCajaUsuario(  {IdCaja: IdCajaSelect.value, idUsuario: item.usuario.id})
        }

        const cargar = () => {
            cargandoDatos.value = true
            try {

                CajaServices.Consultar({
                    idSucursal: store.state.sucursalSelect.id,
                    indActivo: true
                }).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        Lista.value = response.data.datos
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    cargandoDatos.value = false
                }) 
            } catch (error) {
                //alert(error)
                cargandoDatos.value = false
            }
        }

        const ActualizarCajaUsuario = (items) => {
            try {
                CajaServices.ActualizarCajaUsuario(store.state.user.idUsuario, items )
                .then(response => {
                console.log(response)
                if (response.data.estatus == true) {
                    store.commit('setAlert', {
                    message: response.data.mensaje,
                    type: 'success',
                    })
                    cargar();
                } else {
                    store.commit('setAlert', {
                    message: `${response.data.mensaje}: ` + response.data.datos.mensajeError,
                    type: 'warning',
                    })
                }
                })
                .catch(error => {
                console.log(error)
                store.commit('setAlert', {
                    message: error,
                    type: 'error',
                })
                })
                .finally(() => {

                })
            } catch (error) {
                //alert(error)
                store.commit('setAlert', {
                    message: error,
                    type: 'error',
                })
                }
        }
        return{
            dialog,
            store,
            Lista,
            anulando,
            cargandoDatos,
            BuscarEmpleadoRef,
            icons:{
                mdiPencil,
                mdiPlus
            },
            abrir,
            getId,
            cargar,
            abrirModalEmpleado,
            ActualizarCajaUsuario
        }
    }   
}
</script>