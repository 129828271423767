<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" scrollable max-width="500px">
            <v-card>
                <v-card-title>
                    Vincular empleado
                </v-card-title>
                <v-card-text>
                    <SelectEmpleado :cargoEmpleado="[2]" class="mt-4" ref="SelectEmpleadoRef" @GetResult="GetResultEmpleado"></SelectEmpleado>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small class="secondary" @click="dialog = false">Cerrar</v-btn> <v-btn small class="primary" @click="Asignar()">Asignar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { ref } from '@vue/composition-api';
import SelectEmpleado from '../../../configuracion/empleado/componentes/Select.vue'
export default {
    components: {
        SelectEmpleado
    },
    setup(props, contex) {
        const dialog = ref(false);
        const SelectEmpleadoRef = ref(null)
        const abrir = () => {
            dialog.value = true;
            SelectEmpleadoRef.value.model = null
        }
        const Empleado =ref(null)
        const GetResultEmpleado = item => {
            Empleado.value = item 
        }

        const Asignar =()=>{
            if (Empleado.value ){
                contex.emit('getIdEmpleado', Empleado.value)
            } 
        }
        return{
            dialog,
            abrir,
            GetResultEmpleado,
            Asignar,
            SelectEmpleadoRef
        }
    }
}
</script>