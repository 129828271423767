<template>
  <v-row class="match-height">
    <v-col cols="12" sm="6" md="3">
      <statistics-card-vertical
        :change="card1.change"
        :color="card1.color"
        :statistics="card1.statistics"
        :stat-title="card1.statTitle"
        :subtitle="card1.subtitle"
      ></statistics-card-vertical>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <statistics-card-vertical
        :change="card2.change"
        :color="card2.color"
        :statistics="card2.statistics"
        :stat-title="card2.statTitle"
        :subtitle="card2.subtitle"
      ></statistics-card-vertical>
    </v-col>
    <v-col cols="12" sm="6" md="3">
      <statistics-card-vertical
        :change="card3.change"
        :color="card3.color"
        :statistics="card3.statistics"
        :stat-title="card3.statTitle"
        :subtitle="card3.subtitle"
      ></statistics-card-vertical>
    </v-col>

    <v-col cols="12" sm="6" md="3">
      <statistics-card-vertical
        :change="card4.change"
        :color="card4.color"
        :statistics="card4.statistics"
        :stat-title="card4.statTitle"
        :subtitle="card4.subtitle"
      ></statistics-card-vertical>
 
    </v-col>
  </v-row>
</template>
<script>
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import IndicadoresVentaServices from '@/api/servicios/IndicadoresVentaServices.js'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'
import funciones from '@/funciones/funciones'
import moment from 'moment';
export default {
  components: {
    StatisticsCardVertical,
  },
  props: {
    Fecha: String,
  },
  watch: {
    '$store.state.sucursalSelect.id' :function(news, old){
        if (news != old) {
        
        this.cargarDatos()
      }
    },
    Fecha: function (news, old) {
      if (news != old) { 
        this.cargarDatos()
      }
    },
  },
  setup(props) {
    onBeforeMount(() => {
      cargarDatos()
    })
    const datos = ref({})
    const cargandoDatos = ref(false)
    const card1 = ref({
      statTitle: 'Ventas general',
      color: 'success',
      subtitle: '',
      statistics: '0',
      change: '',
    })

    const card2 = ref({
      statTitle: 'Iva',
      color: 'secondary',
      subtitle: '',
      statistics: '0',
      change: '',
    })

    // vertical card options
    const card3 = ref({
      statTitle: 'Venta Real',
      color: 'primary',
      subtitle: '',
      statistics: '0',
      change: '',
    })

    const card4 = ref({
      statTitle: 'Venta Del Mes',
      color: 'warning',
      subtitle: '',
      statistics: '0',
      change: '',
    })

    const cargarDatos = () => {
      try {
        IndicadoresVentaServices.indicadoresVentaGeneralConsultar({
          idSucursal: store.state.sucursalSelect.id,
          fecha:  moment(props.Fecha).format('YYYYMMDD') ,
        })
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              datos.value = response.data.datos
              //Lista.value = response.data.datos
              card1.value = {
                statTitle: 'Ventas general',
                color: 'success',
                subtitle: '',
                statistics: `${funciones.monedaPrincipal().simbolo} ${funciones.formatoNumeric(
                  response.data.datos.montoTotal,
                )}`,
                change: '',
              }
              card2.value = {
                statTitle: 'Iva',
                color: 'secondary',
                subtitle: '',
                statistics: `${funciones.monedaPrincipal().simbolo} ${funciones.formatoNumeric(
                  response.data.datos.montoIva,
                )}`,
                change: '',
              }
              card3.value = {
                statTitle: 'Venta Real',
                color: 'warning',
                subtitle: '',
                statistics: `${funciones.monedaPrincipal().simbolo} ${funciones.formatoNumeric(
                  response.data.datos.montoSubTotal,
                )}`,
                change: '',
              }
              card4.value = {
                statTitle: 'Venta Mes',
                color: 'primary',
                subtitle: '',
                statistics: `${funciones.monedaPrincipal().simbolo} ${funciones.formatoNumeric(
                  response.data.datos.montoTotalMes,
                )}`,
                change: '',
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargandoDatos.value = false
          })
      } catch (error) {
        //alert(error)
        cargandoDatos.value = false
      }
    }

    return {
      card1,
      card2,
      card3,
      card4,
      cargandoDatos,
      cargarDatos,
      funciones,
      datos
    }
  },
}
</script>