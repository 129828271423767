
<template>
  <div class="text-center">
     
    <v-carousel cycle height="auto" hide-delimiter-background show-arrows-on-hover>
      <v-carousel-item v-for="(slide, i) in lista.filter(item => item.tasa != 1)" :key="i">
        <v-sheet>
          <v-card @click="dialog = true"  >
            <v-row class="ma-0 h-full">
              <v-col cols="12" order="1" order-lg="2" class="memberpricing-bg">
                <div class="membership-pricing">
                  <p class="mt-6 text--primary">
                    <span class="text-2xl mb-6">{{ slide.nombre }} {{ slide.simbolo }}</span>
                    <br />
                    <sup class="font-weight-semibold">{{
                      slide.indMultiplicar ? (1 / slide.tasa).toFixed(2) : slide.tasa
                    }}</sup>
                    <sub class="text-xl">{{ funciones.monedaPrincipal().simbolo }}</sub>
                  </p>
                  <span class="my-6 text--secondary text-sm">Tasa actual</span>
                </div>
                <div class="membership-pricing"></div>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Tasas de cambio </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <SelectMoneda
                  ref="SelectMonedaRef"
                  :clearable="true"
                  :dense="true"
                  :outlined="true"
                  :icon="false"
                  v-model="monedaSelect"
                />
              </v-col>
              <v-col>
                <v-text-field
                  hide-details="auto"
                  dense
                  outlined
                  label="Tasa"
                  v-model="tasa"
                  :disabled="CambiandoTasa"
                  :append-outer-icon="icons.mdiContentSave"
                  @click:append-outer="guardarTasa()"
                ></v-text-field>

                <small v-if="monedaSelect.indMultiplicar">Factor: {{ tasaFactor.toFixed(15) }}</small>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div style="height: 300px; overflow-y: auto">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Fecha</th>

                          <th class="text-left">Moneda</th>
                          <th class="text-left">tasa</th>
                          <th colspan="2" class="text-center">ind</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in historialTasas" :key="index">
                          <td>
                            
                            <small>{{ funciones.formatoFecha(item.fechaCreacion) }}</small>
                            <br />
                            <small>{{ funciones.formatoFecha(item.fechaCreacion, 2) }}</small>
                            <TooltipInfo :mensaje="`${item.usuario.empleado.nombre} ${item.usuario.empleado.apellido}`" titulo="Actualizado por:" />
                          </td>
                          <td>{{ item.moneda.nombre }}</td>
                          <td>
                            {{ item.moneda.simbolo }}
                            {{
                              item.moneda.indMultiplicar == true
                                ? funciones.formatoNumeric(1 / item.tasa, 4)
                                : funciones.formatoNumeric(item.tasa, 4)
                            }}
                          </td>
                          <td>
                            <span :class="item.tasa < item.tasaAnterior ? 'success--text' : 'error--text'"
                              >{{ funciones.formatoNumeric((1 - item.tasa / item.tasaAnterior) * 100) }} %</span
                            >
                          </td>
                          <td>
                            <v-icon :color="item.tasa < item.tasaAnterior ? 'success' : 'error'">
                              {{
                                item.tasa < item.tasaAnterior ? icons.mdiArrowUpThick : icons.mdiArrowDownThick
                              }}</v-icon
                            >
                          </td>
                        </tr>
                        <tr>
                          <td colspan="5" class="text-center">
                            <v-btn @click="cargarHistorialTasas()">Cargar Mas</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiChevronUp,
  mdiChevronDown,
  mdiCartPlus,
  mdiShareVariantOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiTrendingUp,
  mdiHelpCircleOutline,
  mdiContentSave,
  mdiArrowDownThick,
  mdiArrowUpThick,
} from '@mdi/js'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import MonedaServices from '@/api/servicios/MonedaServices'
import store from '@/store'
import funciones from '@/funciones/funciones'
import SelectMoneda from '@/views/sistema/configuracion/moneda/componentes/SelectObjeto.vue'
import TooltipInfo from '@/components/TooltipInfo.vue'
export default {
  components: { SelectMoneda, TooltipInfo },
  setup() {
    const dialog = ref(false)
    const monedas = ref([])
    const monedaSelect = ref({})
    const historialTasas = ref([])
    const tasa = ref(0)
    const MonedaDefault = ref({})
    const MonedaPais = ref({})
    const CambiandoTasa = ref(false)
    const tasaFactor = ref(false)
    const SelectMonedaRef = ref(null)
    const lista = ref([])
    const cargando = ref(false)
    const paginaHistorial = ref(1)
    const BuscarMonedas = () => {
      lista.value = []
      cargando.value = true
      try {
        MonedaServices.Consultar()
          .then(response => {
            console.log(response)
            if (response.data.estatus == true) {
              lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargando.value = false
          })
      } catch (error) {
        cargando.value = false
      }
    }
    const guardarTasa = () => {
      if (monedaSelect.value.id > 0 && tasa.value > 0) {
        CambiandoTasa.value = true
        MonedaServices.ActualizarTasa(store.state.user.idUsuario, {
          id: monedaSelect.value.id,
          tasa: tasaFactor.value,
        })
          .then(response => {
            if (response.data.estatus) {
              cargarMonedas()
              BuscarMonedas()
              cargarHistorialTasas()
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'success',
              })
            } else {
              store.commit('setAlert', {
                message: response.data.mensaje,
                type: 'info',
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(item => {
            CambiandoTasa.value = false
          })
      } else {
        store.commit('setAlert', {
          message: 'Debe selecionar una moneda y asignarle un valor valido',
          type: 'info',
        })
      }
    }

    watch(tasa, () => {
      if (monedaSelect.value.indMultiplicar) {
        tasaFactor.value = 1 / tasa.value
      } else {
        tasaFactor.value = tasa.value
      }
    })
    watch(monedaSelect, () => {
      tasaFactor.value = monedaSelect.value.tasa
      tasa.value =  monedaSelect.value.tasa // (1 / monedaSelect.value.tasa).toFixed(4)
    })

    watch(dialog, () => {
      if (dialog.value == true) {
        historialTasas.value = []
        paginaHistorial.value = 1
        cargarHistorialTasas()
      }
    })

    onBeforeMount(() => {
      cargarHistorialTasas()
      BuscarMonedas()
    })
    const cargarMonedas = () => {
      //alert('cargarDatos')
      SelectMonedaRef.value.cargarDatos()
    }

    const cargarHistorialTasas = () => {
      MonedaServices.ConsultarHistorial({ pagina: paginaHistorial.value })
        .then(response => {
          if (response.data.estatus) {
            historialTasas.value = historialTasas.value.concat(response.data.datos.registros)
            paginaHistorial.value = paginaHistorial.value + 1
          }
        })
        .then(error => {
          console.log(error)
        })
        .finally(iten => {})
    }

    return {
      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiCartPlus,
        mdiShareVariantOutline,
        mdiLockOpenOutline,
        mdiStarOutline,
        mdiAccountOutline,
        mdiTrendingUp,
        mdiHelpCircleOutline,
        mdiContentSave,
        mdiArrowDownThick,
        mdiArrowUpThick,
      },
      tasaFactor,
      dialog,
      monedas,
      monedaSelect,
      historialTasas,
      tasa,
      guardarTasa,
      MonedaPais,
      MonedaDefault,
      CambiandoTasa,
      funciones,
      SelectMonedaRef,
      lista,
      cargarHistorialTasas,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 2.75rem;
    top: 9px;
  }
}
</style>

