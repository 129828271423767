var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dvi',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DatePicker',{attrs:{"dense":true,"label":'Fecha',"outlined":true},model:{value:(_vm.FechaGeneral),callback:function ($$v) {_vm.FechaGeneral=$$v},expression:"FechaGeneral"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('IndicadoresGenerales',{ref:"IndicadoresGeneralesRef",attrs:{"Fecha":_vm.FechaGeneral}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('CardTasa')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.AbrirDialog()}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.icons.mdiAccountConvert)+" ")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('h4',[_vm._v("Asignar")]),_c('span',[_vm._v("Cajero")])])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.ExportarPDF()}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icons.mdiFilePdfBox)+" ")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('h4',[_vm._v("Exportar")]),_c('span',[_vm._v("PDF")])])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.exportExcel()}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.icons.mdiFileExcelBox)+" ")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('h4',[_vm._v("Exportar")]),_c('span',[_vm._v("Excel")])])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.exportDatos()}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.icons.mdiDatabaseClock)+" ")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('h4',[_vm._v("Exportar")]),_c('span',[_vm._v("Datos del dia")]),(_vm.exportandoDatos)?_c('span',[_c('br'),_c('span',[_vm._v("Cargando")])]):_vm._e()])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2},on:{"click":function($event){return _vm.exportTodosDatos()}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.icons.mdiDatabaseExport)+" ")])],1),_c('v-col',{attrs:{"cols":"9"}},[_c('h4',[_vm._v("Exportar")]),_c('span',[_vm._v("Exportar toda la data")]),(_vm.exportandoDatos)?_c('span',[_c('br'),_c('span',[_vm._v("Cargando")])]):_vm._e()])],1)],1)],1)]}}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"sm":"12","md":"12","lg":"8"}},[_c('v-card',[_c('div',[_c('v-card-title',[_vm._v(" Arqueo de cajas ")]),_c('div',{staticStyle:{"height":"405px","overflow-y":"auto"}},[_c('TablaCaja',{ref:"TablaCajaRef",attrs:{"Fecha":_vm.FechaGeneral}})],1)],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('div',[_c('v-card-title',[_vm._v(" Arqueo General ")]),_c('div',{staticStyle:{"max-height":"300px","overflow-y":"auto"}},[_c('TablaArqueoGeneral',{ref:"TablaArqueoGeneralRef",attrs:{"Fecha":_vm.FechaGeneral}})],1)],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('div',[_c('v-card-title',[_vm._v(" Productos Vendidos ")]),_c('div',{staticStyle:{"max-height":"300px","overflow-y":"auto"}},[_c('TablaProductosVendidos',{ref:"TablaProductosVendidosRef",attrs:{"Fecha":_vm.FechaGeneral}})],1)],1)])],1)],1),_c('ModalListaCajas',{ref:"ModalListaCajasRef"}),_c('Pdf',{ref:"pdfRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }