
<template>
  
  <div v-if="datos.length > 0 "> 
      <v-simple-table dense height="300px" >
        <template v-slot:default>

          <thead>
            <tr> 
              <th>Id</th>
              <th class="text-left text-uppercase  ">
                <span class=" ">Producto</span>
              </th>
              <th class="text-right text-uppercase  ">
                <span class=" ">Unidades</span>
              </th>
              <th class="text-right text-uppercase  ">
                <span class=" ">Iva</span>
              </th> 
              <th class="text-right text-uppercase  ">
                <span class=" ">Monto</span>
              </th> 
              <th class="text-right text-uppercase  ">
                <span class=" ">Total</span>
              </th> 
            </tr>
          </thead>
          <tbody  > 
            <tr v-for="(item,index) in datos" :key="index">
              <td>
                {{ item.producto.id }}
              </td>
                <td>
               <strong>{{item.producto.nombre}}</strong> 
               <br>
               <span>{{item.producto.barra}}</span>  
                </td>
                <td class="text-right" >{{ funciones.formatoNumeric(item.numeroUnidades) }}</td>
             
                <td class="text-right" >{{funciones.monedaPrincipal().simbolo }} {{ funciones.formatoNumeric(item.iva) }}</td>
                <td class="text-right" >{{funciones.monedaPrincipal().simbolo }} {{ funciones.formatoNumeric(item.pvp) }}</td>
                <td class="text-right" >{{funciones.monedaPrincipal().simbolo }} {{ funciones.formatoNumeric(item.pvp + item.iva) }}</td>
            </tr>
          </tbody> 
        </template>
      </v-simple-table> 
  </div>
  <div v-else class="text-center mb-6">
    No hay datos disponibles
  </div>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import IndicadoresVentaServices from '@/api/servicios/IndicadoresVentaServices'
import { json2excel, excel2json } from 'js2excel';
import funciones from '@/funciones/funciones';
import moment from 'moment';
import store from '@/store'
export default {
  watch: {
    '$store.state.sucursalSelect.id' :function(news, old){
        if (news != old) {
        
        this.consultarArqueo(false)
      }
    },
    Fecha: function (news, old) {
      if (news != old) { 
        this.consultarArqueo(false)
      }
    },
  },
  props: {
    Fecha: String
  },
  components: {
  },
  setup(props) {



    const datos = ref([])
    let counterConsultas = ref(0)
    onBeforeMount(() => {
      setTimeout(() => {
        consultarArqueo(true)
      }, 1000)
    })

    
 
    watch(counterConsultas, () => {
      setTimeout(() => {
        consultarArqueo(true)
      }, 5000)
    })
 


    const consultarArqueo = (actualizar) => {
      IndicadoresVentaServices.indicadoresProductosVendidos({
          idSucursal: store.state.sucursalSelect.id,
          fecha:  moment(props.Fecha).format('YYYYMMDD') ,
        })
        .then(response => {
          console.log(response.data)
          datos.value = response.data.datos
        })
        .catch(error => {
          console.log(error)
        })
        .finally(dato => {
          if (actualizar == true)
            counterConsultas.value += 1
        })
    }

    return {
      datos,
      funciones,
      consultarArqueo
    }
  },
}
</script>
