
<template>
  
  <div height="300px" v-if="datos.length > 0 && datos[0].montoPagoMoneda">
 
    <div v-for="(caja,index) in datos" :key="index">
      
      <v-simple-table dense>
        <template v-slot:default>

          <thead>
            <tr> 
              <th class="text-left text-uppercase  ">
                <span class=" ">Forma Pago</span>
              </th>
              <th class="text-left text-uppercase  ">
                <span class=" ">Moneda</span>
              </th>
              <th class="text-right text-uppercase  ">
                <span class=" ">Monto</span>
              </th> 
            </tr>
          </thead>
          <tbody v-for="(moneda,index) in caja.monedas" :key="index">
            
            <tr v-for="(formasPago,index) in moneda.formasPago" :key="index">

              <td class="text-left">
                {{ formasPago.formaPago.descripcion }}
              </td>
              <td class="text-left">
                {{ moneda.moneda.nombre }}
              </td>
              <td class="text-right">
                {{moneda.moneda.simbolo}} {{ funciones.formatoNumeric(formasPago.montoPagoMoneda) }}
              </td>
              
            </tr>
            <tr class="grey4" v-if="moneda.formasPago.length > 1">
              <td class="text--white" colspan="2">Total {{moneda.moneda.nombre}}</td>
              <td class="text-right  "> {{moneda.moneda.simbolo}} {{funciones.formatoNumeric(moneda.montoPagoMoneda)}}</td> 
            </tr>
          </tbody>
          <tfoot>
            <tr class="grey5">
              <td class="text--white" colspan="2"><span class="white--text">TOTAL</span></td>
              <td class="white--text text-right"><span class="white--text">{{funciones.monedaPrincipal().simbolo}} {{funciones.formatoNumeric(caja.montoPagoReal)}}</span></td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>

      <br>
      <br>
    </div>
  </div>
  <div v-else class="text-center mb-6">
    No hay datos disponibles
  </div>
</template>

<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import IndicadoresVentaServices from '@/api/servicios/IndicadoresVentaServices'
import { json2excel, excel2json } from 'js2excel';
import funciones from '@/funciones/funciones';
import moment from 'moment';
import store from '@/store'
export default {
  watch: {
    '$store.state.sucursalSelect.id' :function(news, old){
        if (news != old) {
        
        this.consultarArqueo(false)
      }
    },
    Fecha: function (news, old) {
      if (news != old) { 
        this.consultarArqueo(false)
      }
    },
  },
  props: {
    Fecha: String
  },
  components: {
  },
  setup(props) {



    const datos = ref([])
    let counterConsultas = ref(0)
    onBeforeMount(() => {
      setTimeout(() => {
        consultarArqueo(true)
      }, 1000)
    })

    
 
    watch(counterConsultas, () => {
      setTimeout(() => {
        consultarArqueo(true)
      }, 5000)
    })
 


    const consultarArqueo = (actualizar) => {
      IndicadoresVentaServices.indicadoresArqueoDeCajasGeneralConsultar({
          idSucursal: store.state.sucursalSelect.id,
          fecha:  moment(props.Fecha).format('YYYYMMDD') ,
        })
        .then(response => {
          console.log(response.data)
          datos.value = response.data.datos
        })
        .catch(error => {
          console.log(error)
        })
        .finally(dato => {
          if (actualizar == true)
            counterConsultas.value += 1
        })
    }

    return {
      datos,
      funciones,
      consultarArqueo
    }
  },
}
</script>
